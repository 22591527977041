.navbar {
  background-color: $adminmaincolor !important;
}

.navbar-nav {
  // align-items: center;
}

.navbar .navbar-left {
  width: 33.33%;
}

.navbar .navbar-center-right {
  width: 66.66%;
}

.navbar .navbar-center {
  width: 50%;
  justify-content: center;
}

.navbar .navbar-right {
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.navbar-nav .btn {
  padding: 0.475rem 1.5rem;
}

.username {
  border: none;
  color: #fff;
}

.btn-chep {
  background-color: $corChep;
}

.btn-lpr {
  background-color: $corLpr;
}

.dropdown-toggle::after {
  margin-left: 1em;
}

.navbar-nav .dropdown-toggle {
  padding-right: 1rem;
}

.navbar-user {
  vertical-align: text-bottom;
}
