@media only screen and (max-width: 1400px) {
  .admin-sidebar {
    margin-left: -175px;
  }

  .admin-main {
    padding: 1rem 1.5rem;
  }
}

@media only screen and (max-width: 1150px) {
  .aside-landing {
    // padding-left: 30%
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav .btn {
    width: 50%;
  }

  .nav-item {
    margin: 0.25rem 0;
  }

  .aside-landing {
    // padding-left: 25%
  }

  .navbar .navbar-right {
    align-items: unset;
  }

  .navbar-user {
    display: none;
  }

  .dataTables_wrapper {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 900px) {
  .dataTables_wrapper {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 880px) {
  .article-login {
    width: 100%;
  }

  .article-landing {
    width: 45%;
  }

  .aside-landing {
    // padding-left: 25%
  }

  .entre-datas {
    margin-bottom: 1rem;
  }

  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_length {
    text-align: left;
  }

  .dataTables_wrapper .dataTables_paginate,
  div.dt-buttons {
    text-align: right;
  }
}

@media only screen and (max-width: 961px) and (orientation: landscape) {
  .section-centro {
    position: relative;
    top: 0;
    left: 0;
    padding: 10px;
    transform: none;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-nav .btn {
    width: 100%;
  }

  .section-pad {
    padding: 3rem 2rem 0;
  }

  .article-landing {
    width: 80%;
    margin-bottom: 3rem;
  }

  .aside-landing {
    // padding-left: 25%;
  }
}

@media only screen and (max-width: 500px) {
  .navbar {
    z-index: 9;
  }

  .article-login {
    padding: 2rem 2rem;
  }

  .article-login h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .section-centro {
    padding: 10px;
  }

  .section-pad {
    padding: 3rem 0 0;
  }

  .section-default {
    padding: 2rem 0.5rem;
  }

  .article-landing {
    width: 100%;
  }

  .aside-landing {
    // padding-left: 20%;
  }

  .dt-buttons button span,
  .dataTables_info,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_paginate a,
  .dataTables_wrapper .dataTables_paginate span {
    font-size: 0.9rem;
  }

  #jp-datatable-buttons_wrapper .dt-buttons .buttons-colvis,
  #jp-datatable-buttons_wrapper .dt-buttons .buttons-excel,
  #jp-datatable-buttons_wrapper .dt-buttons .buttons-pdf,
  #jp-datatable-buttons_wrapper .dt-buttons .buttons-print {
    padding: 0.2rem 0.5rem;
  }

  #jp-datatable-buttons_wrapper .dt-buttons .buttons-colvis {
    height: 32px;
  }

  .article-forn-entidades {
    padding: 2rem 1rem;
  }

  .admin-main {
    min-width: 54%;
    padding: 1rem 0.5rem;
  }

  .main-chep h1,
  .main-lpr h1 {
    font-size: 1.5rem;
  }

  #data-inicio,
  #data-fim {
    width: 42%;
  }

  .entre-datas {
    font-size: 0.8rem;
  }

  .entre-datas .btn {
    padding: 0.1rem 1.4rem;
  }

  .rodape {
    padding: 0.6rem 3rem 0 0;
  }
}

/* BOOTSTRAP MEDIA QUERIES */

@media (min-width: 768px) {
  .bd-sidebar {
    // border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-sidebar {
      position: -webkit-sticky;
      position: sticky;
      z-index: 1000;
      min-height: calc(100vh - 4rem);
    }
  }
}

@media (min-width: 1200px) {
  .bd-sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-links {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .bd-links {
    display: block !important;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    background: #fff;
  }

  footer {
    display: none;
  }
}
