.section-centro {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    width: 100%;
    text-align: center;
    padding: 50px;
    transform: translate(-50%,-50%);
}

.article-login {
    width: 45%;
    margin: 0 auto;
    padding: 2rem 4rem;
    background-color: #246fca;
}

.article-login h1 {
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
}

.btn-custom-login {
    color: #246fca;
    background-color: #ffffff;
    	&:hover{
    		background-color: #fafae5;
    	}
    border-color: #246fca;
    padding: 0.375rem 2.5rem;
}

.section-registo form {
    width: 40%;
    margin: 50px auto 0;
}

.section-registo input {
    margin-bottom: 1rem;
}

.article-login input, .article-nova-pass input {
    margin-bottom: 1rem;
}

.esqueci-pass {
    margin-top: 35px;
}

.esqueci-pass a {
    color: #ffffff;
    	&:hover{
    		color: #fafae5;
    	}
}