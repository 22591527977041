.section-deslocacoes-taticas {
  padding: 3rem 2rem;
  //   background: #fff;
}

.section-deslocacoes-taticas .top h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
  padding: 0.4rem 0.2rem;
  margin: 0;
}

.section-deslocacoes-taticas .top p {
  margin: 0;
  border: 1px solid #000;
  padding: 0.3rem 1.1rem;
}

.section-deslocacoes-taticas .top span {
  padding: 0 0.5rem 0 2rem;
  font-weight: bold;
}

.c-escuro {
  background-color: $cinzento_escuro;
}

.c-medio {
  background-color: $cinzento_medio;
}

.c-claro {
  background-color: $cinzento_claro;
}

.w100 {
  width: 100%;
}

.w16 {
  width: 16%;
}

.w11-35 {
  width: 11.35%;
}

.section-deslocacoes-taticas .top .pad04 {
  padding: 0.1rem 4rem;
}

.wrapper {
  display: grid;
  grid-template-columns: 0.6fr 3.1fr 0.5fr 1.3fr;
  //   grid-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 0 1rem;
}

.box-1 {
  grid-column: 1/3;
}

.box-2,
.box-6 {
  align-self: center;
  justify-self: end;
}

.box-2 span,
.box-6 span {
  padding: 0 0.4rem 0 0;
}

.box-3 {
  align-self: center;
}

.box-3 p,
.box-7 p {
  margin: 0;
  text-align: center;
  padding: 0.1rem 4rem;
  margin: 0;
}

.box-4,
.box-8 {
  justify-self: end;
  align-self: center;
}

.box-9 {
  grid-column: 2/5;
}

.wrapper2 {
  display: grid;
  grid-template-columns: 1.1fr 1.8fr 1.5fr 0.3fr;
  //   grid-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 1rem 1rem;
}

.bx1,
.bx2,
.bx3,
.bx4 {
  align-self: center;
}

.bx1,
.bx3 {
  justify-self: end;
}

.section-deslocacoes-taticas .top .bx2 p {
  padding: 1.05rem;
}

.deslocacoes-taticas-result,
.taticas-folha2 {
  width: 100%;
  padding: 0 1rem 4rem;
}

.tabela-taticas thead th,
.tabela-taticas tbody td,
.tabela-taticas tfoot td {
  vertical-align: middle;
  text-align: center;
  font-size: 0.85rem;
}

.tabela-taticas thead th.pad0 {
  padding: 0 1rem;
}

.tabela-taticas tbody td,
.tabela-taticas tfoot td {
  padding: 0.3rem;
}

.tabela-taticas,
.tabela-taticas .sem-border {
  border: none;
}

.tabela-taticas tfoot td {
  font-weight: bold;
}

.pag {
  font-size: 0.85rem;
  font-weight: bold;
}

.taticas-folha2 {
  font-size: 0.85rem;
}

.taticas-folha2 aside {
  border: 1px solid #000;
}

.taticas-folha2 .bb-none {
  border-bottom: none;
}

.taticas-folha2 p {
  margin: 0;
}

.aux {
  padding: 0.2rem;
  font-size: 0.85rem;
}

.font-bold {
  font-weight: bold;
}

.ta-center {
  text-align: center;
}

.wrap {
  display: grid;
  grid-template-columns: 3fr 1fr;
  //   grid-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 1rem 1rem;
}

.folha2-top-left {
  width: 44%;
}

.folha2-top-right {
  padding: 0;
  width: 55.96%;
}

.folha2-assinaturas {
  padding: 5rem 0.2rem 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.folha2-assinaturas div {
  text-align: center;
  position: relative;
  padding: 0 0 2.5rem;
}

.assin-top p {
  font-style: italic;
}

.taticas-folha2 .assin-linha {
  margin: 4rem auto 0;
  width: 60%;
  border-top: 1px solid #000;
  text-align: center;
  font-size: 0.6rem;
  font-weight: initial;
}

.assin-img {
  position: absolute;
  top: 38px;
  left: 43%;
}

.assin-carimbo {
  position: absolute;
  top: 24px;
  left: 35%;
}

.folha2-bottom {
  padding: 5rem 0 0;
}

.folha2-bottom span,
.folha2-bottom p {
  font-size: 0.7rem;
}

.folha2-bottom span {
  padding: 0 0 0 0.2rem;
}

.folha2-bottom p {
  font-style: italic;
  text-decoration: underline;
  padding: 1rem 0 0.5rem 0.2rem;
}

.tab {
  //   padding: 5rem 0.2rem 1rem;
  display: grid;
  grid-template-columns: 8fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}

.tab h4 {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 0.85rem;
  padding: 0.2rem 0;
  margin: 0;
  align-self: center;
}

.tab h5 {
  font-size: 0.75rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-decoration: none;
  font-style: normal;
  margin: 0;
  padding: 0.2rem 0 0.2rem 2rem;
  line-height: 1.2;
  text-align: left;
  grid-column: 1/6;
  align-self: center;
}

.tab h6 {
  font-size: 0.75rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-decoration: none;
  font-style: normal;
  margin: 0;
  padding: 0.2rem 0;
  line-height: 1.2;
  text-align: center;
  align-self: center;
}

.tab p {
  font-size: 0.75rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-decoration: none;
  font-style: normal;
  margin: 0;
  padding: 0.2rem 0 0.2rem 0.2rem;
  line-height: 1.2;
  text-align: left;
  align-self: center;
}

.b-bottom {
  border-bottom: 1px solid #000;
}

.taticas-folha2 .bt-0 {
  border-top: none;
}

.tabela-taticas .bt-0 {
  border-top: none;
}

.tabela-taticas .br-0 {
  border-right: none;
}

.tabela-taticas .bb-0 {
  border-bottom: none;
}
