.article-landing {
  padding: 2rem 0rem;
  width: 40%;
  margin-bottom: 1rem;
}

.article-forn-entidades {
  width: 44%;
  padding: 2rem;
  background-color: #ffcc80;
}

.aside-landing h3,
.aside-forn-entidades h3 {
  text-align: center;
}

.aside-landing p,
.aside-forn-entidades p {
  text-align: right;
  padding: 0 1.5rem 1.5rem;
  margin: 0;
}

.article-landing .total-kms-ent {
  text-align: center;
  padding: 2rem 1.5rem 0;
  font-size: 1.2rem;
  margin: 0;
}

.aside-forn-entidades h3 {
  font-weight: 400;
}

.art-chep {
  background: $corChep;
  color: #fff;
}

.art-lpr {
  background: $corLpr;
  color: #fff;
}

.entre-datas {
  text-align: center;
}

.entre-datas .btn {
  vertical-align: unset;
  padding: 0.16rem 1.4rem;
}

.forn-entidades h1 {
  padding: 0 0 1rem;
}

.forn-entidades .entre-datas {
  padding-bottom: 2rem;
}

.refs-entidade {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-deslocacoes {
  padding: 3rem 2rem;
}

.deslocacoes-top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem 0;
}

.deslocacoes-top p {
  margin-bottom: 0.5rem;
}

.deslocacoes-result {
  width: 100%;
  padding: 0 4rem 4rem;
}

.assinatura-div {
  position: relative;
}

.assinatura {
  margin: 3.5rem 0 0;
  width: 40%;
  border-top: 1px solid #000;
  text-align: center;
  font-size: 0.8rem;
}

.assinatura-img {
  position: absolute;
  top: -35px;
  left: 16%;
}
