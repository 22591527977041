$adminmaincolor: #1a70cb;
$adminothercolor: #1a70f1;
$sidebarhover: #518ccb;
$corChep: #127abd;
$corLpr: #dd0a25;
$footercolor: #6d6e6a;

//cores folha kms taticas
$cinzento_escuro: #b6afba;
$cinzento_medio: #cbcbd6;
$cinzento_claro: #e0e0e9;
