//Tabela movs_chep_nova.php / movs_lpr_nova.php

.col-5 {
  width: 5%;
}
.col-10 {
  width: 10%;
}
.col-15 {
  width: 15%;
}
.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-30 {
  width: 30%;
}
.col-35 {
  width: 35%;
}
.col-40 {
  width: 40%;
}
.col-45 {
  width: 45%;
}
.col-50 {
  width: 50%;
}

.tabela-form {
  width: 100%;
  margin: 0 auto;
}

.table-folhakms td,
.table-folhakms th {
  vertical-align: baseline;
  font-size: 0.8rem;
}

.table th,
.table td,
.table td input,
.table td select {
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  border-radius: 0;
}

.table-folhakms td,
.table-folhakms td input,
.table-folhakms td select {
  padding: 0.3rem 0.75rem;
}

.table-folhakms th {
  text-align: center;
  padding: 10px;
}

.table-folhakms td {
  padding: 0;
  position: relative;
}

.table-folhakms select {
  position: absolute;
  top: 0;
  bottom: 0;
  height: auto;
  &:focus {
    box-shadow: -0.2px 0px 0px 1.1px #0e62c9;
  }
}

.td-align-centro,
.td-align-centro input {
  text-align: center;
}

.td-align-direita {
  text-align: right;
}

.table th,
.table td,
.table input {
  vertical-align: middle;
}

.table-folhakms input,
.table-folhakms select {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
}

.table .factura-td,
.table .factura-td-conf {
  position: relative;
  padding: 0;
}

.table input.factura-ind,
.table input.factura-ind-conf {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  padding: 0 0.2rem;
}

.table-chep th {
  background-color: $corChep;
  color: #fff;
}

.jpdt-fornent tfoot th {
  background-color: #ffcc80;
}

.jpdt-fornent tfoot .filter-td {
  padding: 0;
}

.jpdt-fornent tfoot select {
  padding: 0px 18px 0px 18px;
  vertical-align: sub;
}

.table-lpr th {
  background-color: $corLpr;
  color: #fff;
}

.aside-landing table {
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.aside-landing .table th {
  font-size: 1rem;
  font-weight: 100;
  vertical-align: middle;
}

.aside-landing .table td {
  font-size: 1rem;
  font-weight: 300;
  vertical-align: middle;
  text-align: left;
}

.aside-forn-entidades table {
  width: 100%;
  margin: 0 auto;
  color: #000;
  border: none;
}

.aside-forn-entidades .table thead th {
  font-weight: 500;
  text-align: center;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 0.5rem 0.75rem;
}
.aside-forn-entidades .table thead th,
.aside-forn-entidades .table tbody td {
  // border-top:none;
  border: none;
  border-right: 1px solid #fff;
}

.aside-forn-entidades .table thead th:last-child,
.aside-forn-entidades .table tbody td:last-child {
  border: none;
}
