// .bd-sidebar{
//   -webkit-box-ordinal-group: 1;
//   -ms-flex-order: 0;
//   order: 0;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//   background-color: $adminmaincolor;
//   top:0;
//   bottom:0;

// }

// .bd-links {
//   // padding-top: 1rem;
//   padding-bottom: 1rem;
//   margin-right: -15px;
//   margin-left: -15px;
// }

// .bd-links h4 a{
// 	color: #fff;
// 	font-size: 1.2rem;
// 	font-weight: 100;
// }

.section-admin-flex {
  display: flex;
}

.admin-sidebar {
  position: relative;

  min-width: 175px;
  background-color: $adminmaincolor;
  min-height: calc(100vh - 6.4rem);
  top: 0;
  bottom: 0;
  transition: margin-left 0.5s;
}

.tog {
  background: url("../../img/filter.png") center center no-repeat;
  background-color: $adminmaincolor;
  position: absolute;
  top: 0;
  left: 100%;
  width: 40px;
  height: 40px;
  border-top: 1px solid #fff;
  cursor: pointer;
  z-index: 9999;
}

.fim-semana input,
.fim-semana select {
  background-color: #9fb5c3 !important;
}

.admin-hide {
  margin-left: -175px;
}

.admin-main {
  min-width: 84%;
  padding: 1rem 3rem;
  width: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #bfd8ca;
}

.bloco {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $adminmaincolor;
  border-bottom: 1px solid #fff;
}

.bloco:first-child {
  border-top: 1px solid #fff;
}

.bloco-body {
  background-color: $adminothercolor;
  box-sizing: unset;
}

.bloco-header button {
  padding: 0.4rem 1rem;
  border-radius: 0;
  color: #42433e;
  &:hover {
    color: #42433e;
    background-color: $sidebarhover;
  }
}

.bloco-body {
  padding: 0.2rem 0;
}

.bloco-body p {
}

.bloco-body a {
  display: block;
  color: #fff;
  margin: 0;
  padding: 0.2rem 1rem 0.2rem 2.5rem;
  &:hover {
    text-decoration: none;
    background-color: $sidebarhover;
  }
}

.bloco-separator {
  padding: 0.4rem 1rem;
  border-radius: 0;
  color: #42433e;
  background: #fff;
  border-bottom: 1px solid transparent;
}

.bloco-separator h3 {
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  border: 1px solid transparent;
}

.bloco-admin {
  border-radius: 0;
  color: #42433e;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.bloco-admin a {
  display: block;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  border: 1px solid transparent;
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background-color: $sidebarhover;
  }
}

.sep-chep h3 {
  color: $corChep;
}

.sep-lpr h3 {
  color: $corLpr;
}

.main-entidades h1 {
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
}

.folha-kms label {
  margin-bottom: 0.1rem;
}

.default-form {
  width: 60%;
  margin: 0 auto;
}

.default-form h2 {
  padding: 2rem 0;
}

.form-group {
  position: relative;
}

.form-group-right {
  margin: 0 0 0 67%;
  padding: 0 0 1rem;
}

.form-group-right label {
  margin-right: 1%;
}

.form-group-right input {
  display: inline;
  width: 50%;
}

#entidade-nome-status,
#entidade-nif-status,
#percurso-nome-status,
#servico-nome-status {
  position: absolute;
  top: 0;
  right: 0;
}

.form-check {
  padding: 0.5rem 1.25rem;
}

#lprDetail,
#chepDetail {
  padding-left: 2rem;
}
