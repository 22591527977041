//@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

body {
  background: #fafae4;
  // font-family: 'Baloo Thambi 2', cursive;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
}

h4 {
  margin: 0;
}

button:focus {
  outline: none;
}

input[type="checkbox"] {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.main-body {
  min-height: 94vh;
}

.navbar-height {
}

.main-section {
  width: 100%;
  height: 100%;
}

.main-chep h1 {
  color: $corChep;
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
}

.main-chep h3 {
  color: $corChep;
}

.main-lpr h1 {
  color: $corLpr;
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
}

.main-lpr h3 {
  color: $corLpr;
}

.section-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.section-pad {
  padding: 7rem 2rem 0;
}

.section-default {
  padding: 2rem 2rem;
}

.section-default h1 {
  padding: 0 0 1rem;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none;
}

.errorvazio {
  background-color: #d4abab !important;
}

.checked-img {
  background: url("../../img/checked.png") center center no-repeat;
}

.uncheck-img {
  background: url("../../img/uncheck.png") center center no-repeat;
}

footer {
  min-height: 6vh;
  background-color: $footercolor;
}

.rodape {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 3rem 0 3rem;
}

.rodape a {
  color: #ffc107;
  text-decoration: none;
}

.rodape p {
  color: #fff;
  text-align: right;
  margin: 0;
}

.section-utilizadores {
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
}

.section-utilizadores h2 {
  margin-bottom: 3rem;
  text-align: center;
}

.article-nova-pass {
  width: 60%;
  margin: 0 auto;
}
